// Generated by Framer (7af8adc)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {CK22uXkbh: {hover: true}};

const cycleOrder = ["CK22uXkbh"];

const variantClassNames = {CK22uXkbh: "framer-v-vi74v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "CK22uXkbh", image: Dghe_IxW9 = {src: new URL("assets/512/ywsckrxrsYjcyYLwJkA4wAYryCg.png", import.meta.url).href, srcSet: `${new URL("assets/512/ywsckrxrsYjcyYLwJkA4wAYryCg.png", import.meta.url).href} 511w, ${new URL("assets/1024/ywsckrxrsYjcyYLwJkA4wAYryCg.png", import.meta.url).href} 1023w, ${new URL("assets/ywsckrxrsYjcyYLwJkA4wAYryCg.png", import.meta.url).href} 1040w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CK22uXkbh", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-GSsue", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-vi74v", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CK22uXkbh"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"CK22uXkbh-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", intrinsicHeight: 1041, intrinsicWidth: 1040, pixelHeight: 1041, pixelWidth: 1040, sizes: "60px", ...toResponsiveImage(Dghe_IxW9)}} className={"framer-100oz2x"} data-framer-name={"Logo_picto_ITC_Data"} layoutDependency={layoutDependency} layoutId={"B62qWxrs2"} transition={transition} {...addPropertyOverrides({"CK22uXkbh-hover": {background: {alt: "", fit: "fit", intrinsicHeight: 1041, intrinsicWidth: 1040, pixelHeight: 1041, pixelWidth: 1040, ...toResponsiveImage(Dghe_IxW9)}}}, baseVariant, gestureVariant)}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-GSsue [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GSsue * { box-sizing: border-box; }", ".framer-GSsue .framer-tgul4b { display: block; }", ".framer-GSsue .framer-vi74v { height: 34px; overflow: visible; position: relative; width: 68px; }", ".framer-GSsue .framer-100oz2x { flex: none; height: 30px; left: calc(50.00000000000002% - 60px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 30px / 2); width: 60px; }", ".framer-GSsue .framer-v-vi74v .framer-vi74v { cursor: pointer; }", ".framer-GSsue.framer-v-vi74v.hover .framer-100oz2x { bottom: 0px; height: unset; left: 0px; right: 0px; top: 0px; width: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 68
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"sK1PS1Xcy":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Dghe_IxW9":"image"}
 */
const Framert1YMzhpF8: React.ComponentType<Props> = withCSS(Component, css, "framer-GSsue") as typeof Component;
export default Framert1YMzhpF8;

Framert1YMzhpF8.displayName = "logo2";

Framert1YMzhpF8.defaultProps = {height: 34, width: 68};

addPropertyControls(Framert1YMzhpF8, {Dghe_IxW9: {__defaultAssetReference: "data:framer/asset-reference,ywsckrxrsYjcyYLwJkA4wAYryCg.png?originalFilename=Logo+%2Bpicto+ITC+Data.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framert1YMzhpF8, [])